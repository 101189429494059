$(function () {
    // Header sticky
    var header = document.getElementById("site_head_wrap");

    function onWinScroll() {
        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {
            if (scrollTop === 0) {
                header.classList.remove("sticky");
                $("body").removeClass("header_sticky");
            }
        } else {
            if (scrollTop !== 0) {
                header.classList.add("sticky");
                $("body").addClass("header_sticky");
            }
        }

        // hide li result on scrolling
        if ($( "#search_results li" ).length > 0 && $( "#search_results li" ).is( ':visible' )) {
            if (scrollTop !== 0) {
                $( "#search_results li" ).hide();
            }
        }

        if ( $( '#search_page' ).length ) {
            // change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
            if ( $( '.wrap_rolloverproduit' ).length ) {
                fixAchatExEnfant();
            }
        }
    }

    $(window).on("scroll", onWinScroll);
    onWinScroll();

    // Ajout class lookbook sur body
    if ($('body').hasClass('category') && $('.rayon_lookbook').length) {
        $('body').addClass('lookbook');
    }

    // Ajout background sur rollover header
    $('.head_top .block_top_right').on({
        mouseenter: function () {
            $('#site_head_wrap').addClass('hovering');
            // needed to change back menu items color on hover
            $('#site_head_wrap .block_top_right').addClass('hover_color_change');
        },
        mouseleave: function () {
            $('#site_head_wrap').removeClass('hovering');
            // needed to change back menu items color on hover
            $('#site_head_wrap .block_top_right').removeClass('hover_color_change');
        }
    });

    // Preheader
    window.setTimeout(function () {
        if ($(".bandeau_header .swiper-slide").length > 1) {
            promoSwiper = new Swiper(".bandeau_header .swiper-container", {
                slidesPerView: 1,
                speed: 1500,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                autoplayDisableOnInteraction: false
            });
        }

        if ($(".bandeau_header").length) {
            $("body").addClass("bandeauDelivery");
        }

    }, 500);

    // Rollover Lang
    $('.wrap_lang').on('click', function () {
        $(this).toggleClass('show');
    });

    // Rollover Compte
    if ($('#my_account').hasClass('is_logged')) {
        $('#my_account').on({
            mouseenter: function () {
                $('#shad_menu').addClass('actif');
            },
            mouseleave: function () {
                $('#shad_menu').removeClass('actif');
            }
        });
    }

    // Rollover Wishstlist + Panier
    $(".my_basket, .my_wishlist").on("mouseenter", function () {
        topCartAppear($(this));
    });

    $(".my_basket, .my_wishlist").on("mouseleave", function () {
        topCartDesappear($(this));
    });
    
    // Permet d'ouvrir un sous-menu spécifié et de fermer tous les autres sous-menus ouverts au même niveau. Si un sous-menu est déjà ouvert, elle le ferme.
    function toggleSubMenu(element, selector) {
        var $this = $(element);
        var subMenu;

        if (selector) {
            subMenu = $this.parent().find(selector);
        }
        if (subMenu.length) {
            // we store the value of the link
            var locationHref = $this.href != undefined ? $this.href : $this.data("href");

            if (subMenu.hasClass('opened')) {
                subMenu.slideUp().removeClass('opened');
                // redirection on the second click, will also redirect if href set to javascript:;
                location.href = locationHref != undefined ? locationHref : '/';

                if (selector === '.submenu_wrapper') {
                    subMenu.find('.objet_cat.opened').slideUp().removeClass('opened');
                }
            } else {
                subMenu.slideDown().addClass('opened');
                $this.siblings().find(selector + '.opened').slideUp().removeClass('opened');
            }
        }
    }
    
    $('#left_menu .first_level_item').on('click touch', function(event) {
        toggleSubMenu(this, '.submenu_wrapper');
    });
    
    $('#left_menu .ss_cat_name.has_objects').on('click touch', function(event) {
        toggleSubMenu(this, '.objet_cat');
    });

    // Au hover du menu on inverse la couleur du logo. Blanc => noir.

    $('#left_menu').hover(
        function() {
            const img = $("#site_head_wrap div.block_top_left img");
            $(this).is(':hover') ? img.addClass('invert_logo') : img.removeClass('invert_logo');
        }
    );

    // Sert à fermer le menu pour un clic en dehors de celui-ci.
    // Si ce n'est pas sur le menu alors on supprime les classes qui affichient les sous catégories, on annule l'inversion de couleur du logo. 

    $(document).on('click', function(event) {
        if (!$(event.target).closest('#left_menu').length) {
            $('#left_menu .opened').removeClass('opened').slideUp();
            $("#site_head_wrap div.block_top_left img").css('filter', '');
        }
    });
    
    // Afin d'eviter un effet de bord.
    $('#left_menu').on('click', function(event) {
        event.stopPropagation();
    });
  
    // ROLL HOVER MENU END
            

    // change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
    if ($('.wrap_rolloverproduit').length) {
        fixAchatExEnfant();
    }

    // Menu responsive
    $('#trigger_menu_responsive').on('click touch', function () {
        if (!$(this).hasClass('open')) {
            $(this).addClass('open');
            $('#left_menu').addClass('open');
            $('#shad_menu_responsive').addClass('actif');
            $('#site_head_wrap').addClass('responsive_menu_open');
        } else {
            $(this).removeClass('open');
            $('#left_menu').removeClass('open');
            $('#shad_menu_responsive').removeClass('actif');
            $('#site_head_wrap').removeClass('responsive_menu_open');
        }
    });

    // FP Guide taille scrollBar
    if (1 == 1) {
        $("#sizeguideBox").overlayScrollbars({});
    }

    // Init swipers
    if ($(".satellite_univers_recyclage .swiperTgsearch .swiper-slide").length > 1) {
        swiperBuilder('moduleSwiper');
    }

    if ($(".cms_page .cover_module_container .swiper-slide").length > 1) {
        cmsCoverSwiper = new Swiper(".cover_module_container", {
            autoplay: true
        });
    }

    // HP Social Module IG
    window.setTimeout(function () {
        if ($('#socialIGSwiper .swiper-container .swiper-slide').length > 1) {
            hashtagHomeSwiper = new Swiper("#socialIGSwiper .swiper-container", {
                autoplay: true,
                slidesPerView: 4,
                spaceBetween: 6,
                breakpoints: {
                    1340: {
                        initialSlide: 1,
                        slidesPerView: 'auto'
                    }
                },
            });
        }
    }, 500);

    // Home page TG module Swiper
    if ($(".homepage #moduleSwiper .swiper-slide").length > 1) {
        swiperBuilder('moduleSwiper');
    }

    // ZoomBox Thumbs Swiper
    if ($("#thumbs_scroller").length) {
        productZoomThumbs = new Swiper('#thumbs_scroller', {
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            slidesPerView: 4,
            spaceBetween: 8,
            direction: 'vertical',
            navigation:
            {
                nextEl: '.thumb_wrapper .zoomNext',
                prevEl: '.thumb_wrapper .zoomPrev',
            },
        });
    }

    if ($("#zoom_box_wrapper").length) {
        var activeImage = $('#jqzoom .swiper-slide-active').index();

        // ZoomBox Product Swiper
        var zoom_productVisu = new Swiper('#zoom_box_wrapper', {
            slidesPerView: 1,
            initialSlide: activeImage,
            loadOnTransitionStart: true,
            thumbs: {
                swiper: productZoomThumbs
            },
            navigation:
            {
                nextEl: '.zoom_main_wrapper .zoomNext',
                prevEl: '.zoom_main_wrapper .zoomPrev',
            },
            lazy: true,
        });

        // if #zoom_box_wrapper .swiper-wrapper only contains one slide, we hide the arrows
        if ($('#zoom_box_wrapper .swiper-wrapper .swiper-slide').length <= 1) {
            $('.zoom_main_wrapper .zoomNext').hide();
            $('.zoom_main_wrapper .zoomPrev').hide();
        } else {
            $('.zoom_main_wrapper .zoomNext').show();
            $('.zoom_main_wrapper .zoomPrev').show();
        }
    }

    if ($("#product_associations_manual_slider .swiper-slide").length) {
        swiperBuilder('product_associations_manual_slider');
    }

    if ($("#product_associations_rayon_slider .swiper-slide").length) {
        swiperBuilder('product_associations_rayon_slider');
    }

    if ($('body').hasClass('product_page') && $('#ligne_couleur').length) {
        // Getting selected color
        var selected_color = $('#productInfos input[name*="itm_color"]:checked').data('nom');

        // Setting selected color
        $('#ligne_couleur .prod_listes > label').append('<span> : ' + selected_color + '</span>');
    }

    // Tunnel panier ~ Moving quantity
    if ($('body').hasClass('cart') && $('.cart_product_sizecol.quantity').length) {
        $('.cart_product_line').each(function () {
            var btnQty = $(this).find($('.cart_product_modify'));
            var qtyLine = $(this).find($('.cart_product_sizecol.quantity'));

            btnQty.appendTo(qtyLine);
        });

        // Moving quantity on ajax load
        $(document).ajaxComplete(function () {
            $('.cart_product_line').each(function () {
                var btnQty = $(this).find($('.cart_product_modify'));
                var qtyLine = $(this).find($('.cart_product_sizecol.quantity'));

                if (!qtyLine.find(btnQty).length) {
                    btnQty.appendTo(qtyLine);
                }
            });
        });
    }

    // Tunnel panier ~ Lightbox produit offert
    if ($('body').hasClass('cart') && $('#selection-offer-content').length) {
        $('#selection-offer-content').overlayScrollbars({});;
    }

    // Tunnel livraison ~ Scrollbar
    if ($('body').hasClass('in_tunnel') && $('#choix_relais_relais').length) {
        $('#choix_relais_relais .sidebar-right').overlayScrollbars({});
    }

    // Tunnel livraison + commander
    if ($('body').hasClass('in_tunnel') && $('#montant_txt').length) {
        $('#montant_txt').appendTo('.bill_line.total_produit label + .price');
    }

    // Tunnel commander
    if ($('body').hasClass('checkout') && $('#cart_total .wrapper_payment').length) {
        $('#cart_total .wrapper_payment').prependTo($('#tunnel_right_col'));
    }

    // Tunnel commander ~ Tablette
    if ($('body').hasClass('checkout') && $('.bloc_paid_by_link_hipay').length) {
        if ($('.bloc_paid_by_link_hipay .w-input-element').val().length) {
            $('.bloc_paid_by_link_hipay .w-input-element').parent().addClass('w-nonempty');
        } else {
            $('.bloc_paid_by_link_hipay .w-input-element').parent().removeClass('w-nonempty');
        }

        $('.bloc_paid_by_link_hipay .w-input-element').on('input', function () {
            if ($('.bloc_paid_by_link_hipay .w-input-element').val().length) {
                $(this).parent().addClass('w-nonempty');
            } else {
                $(this).parent().removeClass('w-nonempty');
            }
        });
    }

    // Tunnel confirmation
    window.setTimeout(function () {
        if ($('body').hasClass('bankThanks') && $('h1').length) {
            $('h1').prependTo('.thanksWrapper');
        }
    }, 100);

    // Ajout classe PW forget
    if ($('.wrapper_password').length) {
        $('body').addClass('lost_pw');
    }

    // Footer reassurance ~ Redirection sur question FAQ
    if ($('body').hasClass('faq')) {
        function Reassurancefaq() {
            // Reassurance livraison
            if (~window.location.href.indexOf("#livraison")
                || ~window.location.href.indexOf("#delivery")
                || ~window.location.href.indexOf("#segui_il_tuo_ordine")
                || ~window.location.href.indexOf("#levering")
                || ~window.location.href.indexOf("#consegna")) {
                $('#theme_page').addClass('actif');
                $('.faq_theme_4').addClass('active');
                $('.faq_questions_4').removeClass('cache');
                $('.faq_question_14').addClass('active');
                $('.faq_reponse_14').show().removeClass('cache');

                $("html, body").delay(1000).animate({
                    scrollTop: $('.faq_element.active').offset().top - 175
                }, 1500);
            }

            // Reassurance retour
            if (~window.location.href.indexOf("#retour")
                || ~window.location.href.indexOf("#ritorno")
                || ~window.location.href.indexOf("#return")) {
                $('#theme_page').addClass('actif');
                $('.faq_theme_5').addClass('active');
                $('.faq_questions_5').removeClass('cache');

                $("html, body").delay(1000).animate({
                    scrollTop: $('.faq_element.active').offset().top - 175
                }, 1500);
            }

            // Reassurance paiement
            if (~window.location.href.indexOf("#paiement")
                || ~window.location.href.indexOf("#pagamento")
                || ~window.location.href.indexOf("#betaling")) {
                $('#theme_page').addClass('actif');
                $('.faq_theme_3').addClass('active');
                $('.faq_questions_3').removeClass('cache');
                $('.faq_question_29').addClass('active');
                $('.faq_reponse_29').show().removeClass('cache');

                $("html, body").delay(1000).animate({
                    scrollTop: $('.faq_element.active').offset().top - 175
                }, 1500);
            }
        }

        /* On click footer/reassurance */
        $('[href*="#pagamento"], [href*="#paiement"], [href*="#betaling"]').click(function() {
            $('#theme_page').addClass('actif');
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('.faq_theme_3').addClass('active');
            $('.faq_questions_3').removeClass('cache');
            $('.faq_question_29').addClass('active');
            $('.faq_reponse_29').show().removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        $('[href*="#ritorno"], [href*="#retour"], [href*="#return"]').click(function() {
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('#theme_page').addClass('actif');
            $('.faq_theme_5').addClass('active');
            $('.faq_questions_5').removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        $('[href*="#segui_il_tuo_ordine"], [href*="#enn_bestelling_volgen"]').click(function() {
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('#theme_page').addClass('actif');
            $('.faq_theme_4').addClass('active');
            $('.faq_questions_4').removeClass('cache');
            $('.faq_question_14').addClass('active');
            $('.faq_reponse_14').show().removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        $('[href*="#consegna"], [href*="#livraison"], [href*="#levering"]').click(function() {
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('#theme_page').addClass('actif');
            $('.faq_theme_4').addClass('active');
            $('.faq_questions_4').removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        setTimeout(function() {
            Reassurancefaq();
        }, 500);
    }

    // Bloc seo
    if ($('.see_more_txt').length) {
        $('.see_more_txt').on('click', function () {
            if (!$(this).hasClass('open')) {
                $('.bloc_seo_description').addClass('active');
                $(this).addClass('open');
                $(this).html(Translator.translate('see_less'));
            } else {
                $('.bloc_seo_description').removeClass('active');
                $(this).removeClass('open');
                $(this).html(Translator.translate('see_more'));
            }
        });
    }

    //cart code promo nb product line
    if($('#cart_total .used_cp').length) {
        $('.top_nb_products').addClass('visible');
    } else {
        $('.top_nb_products').removeClass('visible');
    }

    // HP Cover module Swiper
    if ($('#coverSwiper .vimeo_video').length || $('#coverSwiper .youtube_video').length) {
        if ($('#coverSwiper .vimeo_video').length) {
            var playerVimeo = $('#coverSwiper .vimeo_video').length ? new Vimeo.Player(document.querySelector('#coverSwiper .vimeo_video')) : '';

            playerVimeo.pause();
        }

        setTimeout(function() {
            var coverSwiper = new Swiper('#coverSwiper', {
                roundLengths: true,
                setWrapperSize: true,
                slidesPerView: 1,
                allowTouchMove: false
            });

            function initCoverSlider(target) {
                if ($('.swiper-slide-active .vimeo_video').length && $('#coverSwiper .vimeo_video').attr('id') !== undefined) {
                    playerVimeo.setCurrentTime(0.0);
                    playerVimeo.play();

                    playerVimeo.getDuration().then(function(duration) {
                        setTimeout(function() { // Get next slide at the end of the video
                            if (target == "nextSlide") {
                                coverSwiper.slideNext();
                            } else {
                                coverSwiper.slideTo(0);
                            }
                        }, duration * 1000);
                    });
                } else if ($('.swiper-slide-active .youtube_video').length && $('#coverSwiper .youtube_video').attr('id') !== undefined) {
                        playerYT.playVideo();

                        var youTubeDuration = playerYT.getDuration() * 1000;

                        setTimeout(function() { // Get next slide at the end of the video
                            if (target == "nextSlide") {
                                coverSwiper.slideNext();
                            } else {
                                coverSwiper.slideTo(0);
                            }
                        }, youTubeDuration);
                } else {
                    setTimeout(function() {
                        if (target == "nextSlide") {
                            coverSwiper.slideNext();
                        } else {
                            coverSwiper.slideTo(0);
                        }
                    }, 3000);
                }
            }

            coverSwiper.on('slideChangeTransitionEnd', function () {
                var nbrSlides = coverSwiper.slides.length;
                var lastIndex = this.realIndex;

                if (lastIndex == (nbrSlides - 1)) {
                    initCoverSlider("firstSlide");
                } else {
                    initCoverSlider("nextSlide");
                }
            });

            initCoverSlider("nextSlide");
        }, 600);
    } else {
        // If no video we keep old swiper initiation
        if ($( "#coverSwiper .swiper-slide" ).length > 1) {
            coverModuleHomeSwiper = new Swiper( "#coverSwiper:not(.no-swiper)", {
                loop: true,
                 autoplay: {
                    delay: 6000,
                },
                slidesPerView: 1,
                navigation: {
                    nextEl: ".cover-module .cover-next",
                    prevEl: ".cover-module .cover-prev",
                },
            }, 500);
        }
    }

    if ($('body').hasClass('store_locator')) {
        var schedulesArg = document.querySelectorAll('.satellite_store_details .bloc_horraire .time');
        changeSymbolSchedule(schedulesArg);
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
            }, 500);
        }
    });
});


/* You Tube API call */
if ( $( '#coverSwiper .youtube_video' ).length ) {
    var tag = document.createElement( 'script' );

    tag.src = "https://www.youtube.com/iframe_api";

    var firstScriptTag = document.getElementsByTagName( 'script' )[ 0 ];

    firstScriptTag.parentNode.insertBefore( tag, firstScriptTag );

    var YTiframeID = $( '#coverSwiper .youtube_video' ).attr( 'id' ); // Get the YT video iframe id
    var playerYT;

    function onYouTubeIframeAPIReady() {
        playerYT = new YT.Player( YTiframeID, {} );
    }
}

if ($('body.product_page').length) {

    setTimeout(function() {
        // do no slider if there's only one slide
        if ($('#visual_scroller .swiper-wrapper .swiper-slide').length <= 1) {
            $('.product_left_image').addClass('appear');
            return;
        }

        var productThumbs = new Swiper('#visual_scroller', {
            slidesPerView: 4,
            spaceBetween: 11,
            direction: 'vertical',
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });

        var main_productVisu = new Swiper('#jqzoom', {
            direction: 'vertical',
            slidesPerView: 1,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            thumbs: {
                swiper: productThumbs
            },
            navigation: {
                nextEl: '#visual_scroller_wrapper .thumbNext',
                prevEl: '#visual_scroller_wrapper .thumbPrev',
            },
            preloadImages: false,
            lazy: true,
            loadPrevNext: true,
            loadOnTransitionStart: true,
            mousewheel: true,
            loop: true,
        });

        $('.product_left_image').addClass('appear');
    }, 250);
    
    // if .visual_scroller .swiper-wrapper only contains one slide, we hide the arrows
    if ($('#visual_scroller .swiper-wrapper .swiper-slide').length <= 1) {
        $('#visual_scroller_wrapper .thumbNext').hide();
        $('#visual_scroller_wrapper .thumbPrev').hide();
    }
    
}

function initSwiperProductVisualScroller() {
    
}

// change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
function fixAchatExEnfant() {
    $('.wrap_rolloverproduit').each(function() {
        var size_1 = $(this).find('.productSizeFieldset .size_list .choices_list .form_itm');
        var taille_len = $.trim($(size_1[0]).find('span').text()).length;

        if (taille_len > 3) {
            $(this).addClass('calibrate');
        }
    });
};

function productCartSlide() {
    $(".top_nb_products").click(function(){
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
        //init scrollbar panier
        if ($(".top_nb_products.open").length) {
            setTimeout(function() {
                $("#cart_total .total_produit_dropdown").overlayScrollbars({});
            }, 200);
        }
    });
}

productCartSlide();

// Utiliser pour regrouper les produits par deux pour chaque slides
function swiperBuilder(id) {
    var items = $("#" + id + " .swiper-wrapper .swiper-slide");
    var items_tmp = [];
    var items_tmp_bloc = [];
    var nb_items = items.length;

    // On les affiche les portraits 2 par 2 et les paysage 1 par 1
    var i = 0;

    items.each(function (index) {
        var elt = $(this);

        elt.removeAttr("class");
        elt.removeAttr("style");
        elt.addClass("item");

        // On va "regrouper" les bloc portrait et laisser seule les bloc paysage. Le tous en gardant le même ordre
        items_tmp_bloc.push(elt);

        // Si le nombre d'image par 2 est attent on transfère le tableau dans le tableau final
        if (items_tmp_bloc.length === 2) {
            items_tmp.push(items_tmp_bloc);
            // On vide le bloc
            items_tmp_bloc = [];
        }
    });

    var swiper_wrapper = $("#" + id + " .swiper-wrapper");

    // On vide l'ancien swiper
    swiper_wrapper.html('');

    // On va parcourir les image réunie
    $.each(items_tmp, function (index, value) {
        var bloc = $('<div>', { class: 'swiper-slide' }).clone();

        if (value.length > 1) {
            var length = value.length;

            for (var i = 0; i < length; i++) {
                value[i].appendTo(bloc);
            }
        } else {
            value.appendTo(bloc);
        }

        // On remplie avec les images réorganisées
        bloc.appendTo(swiper_wrapper);

        return '';
    });

    new Swiper($("#" + id), {
        slidesPerView: 1,
        spaceBetween: 31,
        navigation: {
            nextEl: "#" + id + " .swiper-button-next",
            prevEl: "#" + id + " .swiper-button-prev",
        }
    });

    $("#" + id).css('opacity', '1');
}

// Commande
var global_id_transporteur = '';

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {
    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_' + (multiTransp ? idOrderTransp : idOrder);
    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    openMultiShad(divId);
    swiper_demande_de_retour();
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&send_mail=true';
    }

    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, multiTransp) {

    var has_errors = false;
    $(".return_quantity").each(function() {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0  && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form_' + idOrder).serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp;

        $.ajax({
            url : path_relative_root + 'ajax_create_etiquette.php',
            type : 'post',
            data : data,
            success : function (res) {
                location.reload();
            }
        });
    }
}


function close_popup_return(obj_elem) {
    $("#popup_numero_retour_" + obj_elem).removeClass('actif');
    $("#shad").removeClass('actif');
    location.reload();
}

// Back to top Rayon Btn
var backToTop = $("#back_to_top");
backToTop.click(function () {
    $("html,body").animate({
        scrollTop: 0
    }, "slow")
});

$(window).on("scroll touchmove", function () {
    var o = $(window).scrollTop();
    o > 650 ? backToTop.addClass("show") : backToTop.removeClass("show")
});

if ($('body').hasClass('product_page')) {
    $('#zoomBox .img_large').on('click', function () {
        if (!$(this).hasClass('zoomTriggered')) {
            $(this).addClass('zoomTriggered');
            zoomMainimg($(this));
        } else {
            $(this).removeClass('zoomTriggered');
            $(this).css({ 'transform-origin': 'initial' });
            $(this).css({ 'transform': 'scale(' + 1 + ')' });
        }
    });

    $('#zoomBox .img_large.zoomTriggered').each(function () {
        $(this).on('mouseenter', function () {
            zoomMainimg($(this));
        });
    });

    $('.zoom_main_wrapper').on('click', function (e) {
        if (e.target == this) {
            closeMultiShad('zoomBox');
        }
    });
}

function zoomMainimg(el) {
    var main_img = el;
    var zoomed_img = $('#zoomBox .zoomTriggered');
    var zoom_scale = 2;

    // Preload zoomed image
    $('<img>').attr('src', zoomed_img.attr('src'));

    // Mouse actions
    main_img.on('mouseout', function() {
        zoomed_img.css({
            'transform-origin': 'initial',
            'transform': 'scale(1)'
        });
    });

    main_img.on('mousemove', function(e) {
        var xPercent = ((e.pageX - main_img.offset().left) / main_img.width()) * 50;
        var yPercent = ((e.pageY - main_img.offset().top) / main_img.height()) * 50;
        zoomed_img.css({
            'transform-origin': xPercent + '% ' + yPercent + '%',
            'transform': 'scale(' + zoom_scale + ')'
        });
    });
}

// Rayon filter triggers
function filterTrigger(el) {
    if ($('#filter_sticky').length) {
        const trigger = $(el);
        const filterWrapper = $('.left_filter_wrapper');
        const filterSort = $('.filter_container');
        const shadow = $('#shad_menu');

        if (!trigger.hasClass('triggered')) {
            trigger.addClass('triggered');
            filterWrapper.addClass('actif');
            filterSort.addClass('actif');
            shadow.addClass('active');
            $('.filter_wrapper').not(trigger).removeClass('triggered');

            shadow.on('click', function () {
                closeFilter();
            })
        } else {
            closeFilter();
        }

        function closeFilter() {
            trigger.removeClass('triggered');
            filterWrapper.removeClass('actif');
            filterSort.removeClass('actif');
            shadow.removeClass('active');
        }

        // change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
        if ( $( '.wrap_rolloverproduit' ).length ) {
            fixAchatExEnfant();
        }
    }
}

function triggerFunctionOnSearchEnd() {
    // Recherche Rayon sans résultats swiper
    if ($(".no_results_wrapper .swiperTgsearch .swiper-slide").length > 1) {
        swiperBuilder('moduleSwiper');
    }

    // change display dependending on the length of characters in a products' size (e.g S, L || 6 ans , 8ans ).
    if ( $( '.wrap_rolloverproduit' ).length ) {
        fixAchatExEnfant();
    }
}

// Filtre rayon vue par 2 ou 3
function viewByDefault(){
    if($('#list_item').length && $('#list_item').hasClass('byThree')){
        $('#list_item').removeClass('byThree');
        $('#list_item').addClass('byDefault');
        $('#viewChanger .byDefault').addClass('actif');
        $('#viewChanger .byThree').removeClass('actif');
        $('#viewChanger .byDefault').addClass('selected');
        $('#viewChanger .byThree').removeClass('selected');
        $('#viewChanger .byThree').addClass('noselect');
        $('#viewChanger .byDefault').removeClass('noselect');
    }
}

function viewByThree(){
    if($('#list_item').length && $('#list_item').hasClass('byDefault')){
        $('#list_item').removeClass('byDefault');
        $('#list_item').addClass('byThree');
        $('#viewChanger .byDefault').removeClass('actif');
        $('#viewChanger .byThree').addClass('actif');
        $('#viewChanger .byThree').addClass('selected');
        $('#viewChanger .byDefault').removeClass('selected');
        $('#viewChanger .byDefault').addClass('noselect');
        $('#viewChanger .byThree').removeClass('noselect');
    }
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {
    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {
        str_year = '&year=' + year;
    }

    if (reload !== undefined) {
        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("onglet_" + i + "_mid")) {
        if (type == i) {
            $("#onglet_" + i + "_left").attr("class", "left-on");
            $("#onglet_" + i + "_mid").attr("class", "mid-on");
            $("#onglet_" + i + "_right").attr("class", "right-on");
        } else {
            $("#onglet_" + i + "_left").attr("class", "left-off");
            $("#onglet_" + i + "_mid").attr("class", "mid-off");
            $("#onglet_" + i + "_right").attr("class", "right-off");
        }
        i++;
    }

    var container = document.getElementById(elementID);
    if (container) {
        var response = ajax_file(parentPath + create_link('ajax_account_orders_by_type') + '?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {
                $(container).html(response);
            }
            return true;
        } else {
            container.innerHTML = '';
            return false;
        }
    } else {
        return false;
    }
}

// Tunnel FAQ
function fadePanier(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') {
            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {
        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {
        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

function btnLoaderUnloading(btn) {
    "use strict";
    var loader = btn.next(".loader");
    if (loader.length > 0) {
        setTimeout(function() {
            loader.removeAttr("style").parent().removeClass("loading")
        }, 200);
    }
}

// Homepage - footer: inscription NL
if ($('.bloc-news-footer .w-newsletter-form .w-email-input').length) {
    var target = $('.bloc-news-footer .w-newsletter-form .w-email-input')
    var inputField = $(".bloc-news-footer .w-newsletter-form .w-input.w-email-input input");

    inputField.on('click tap touch', function() {
        setTimeout(function () {
            $('.bloc-news-footer .w-newsletter-form').addClass('w-form-is-focus');
        }, 150);
    });

    inputField.on('focusout', function() {
        setTimeout(function () {
            $('.bloc-news-footer .w-newsletter-form').removeClass('w-form-is-focus');
        }, 150);
    });

    inputField.on('focusout', function() {
        if ($('.bloc-news-footer .w-newsletter-form').hasClass('w-form-is-focus') && target.hasClass('w-nonempty')) {
            setTimeout(function () {
                $('.bloc-news-footer .w-newsletter-form').addClass('w-form-is-focus');
            }, 150);
        }
    });

    inputField.on('focusout', function() {
        if ($('.bloc-news-footer .w-newsletter-form').hasClass('w-form-is-focus') && !inputField.val() && target.hasClass('w-nonempty') && target.hasClass('w-has-error') && target.hasClass('w-focused')) {
            setTimeout(function () {
                $('.bloc-news-footer .w-newsletter-form').removeClass('w-form-is-focus');
            }, 150);
        }
    });
};

function loadProductSelectionOffert() {
    var product_id = $(this).data('product-id');
    var product_id_parent = $(this).data('product-parent-id');
    var product_color = $(this).val();
    var $form = $(this).parents('form');
    var $img = $form.find('img');
    var title = $form.find('.titleOfferedProd');

    if (product_id > 0 && product_color > 0) {
        data = {
            produit_id : product_id,
            product_color : product_color,
        };

        $.ajax({

            type: 'post',
            url: path_relative_root + 'ajax_get_product_selection_offert.php',
            data: data,
            success: function (res) {

                try {
                    res = $.parseJSON(res);
                } catch (e) {
                    console.error('parseJSON');
                    return;
                }

                if (res.success && res.stock > 0) {
                    $img.attr('src',res.picture_path);

                    if (res.title !== '') {
                        title.html(res.title + '<span>' + res.subtitle + '</span>');
                    }

                    $form.find('input[name="product_id"]').val(product_id);
                    if (product_id_parent) {
                        $.each(res.sizes, function(size_id, has_stock) {
                            if (has_stock) {
                                $('#div_size_sel_offer_' + product_id_parent + '_' + size_id).removeClass('cache');
                            } else {
                                $('#div_size_sel_offer_' + product_id_parent + '_' + size_id).addClass('cache');
                            }
                        });
                    }
                } else {
                    $(this).prod('checked', false);
                }
            }
        });

        let parentFrom = $(this).closest('.js-form-selection-offer');
        if (!parentFrom.hasClass('actif')) {
            parentFrom.click();
        }
    }
}

// Permet de faire apparaître message de sélection si la taille n'a pas été choisie.
function checkSizeClicked() {
    $("#sizeerror").show().animate({opacity: 1}, 500, function() {
        setTimeout(function() {
            $("#sizeerror").animate({opacity: 0}, 500, function() {
                $(this).hide();
            });
        }, 3000);
    });
}

$("#bloc_availability").on("click", function () {
    if ($("#bloc_availability").hasClass("inactiv")) {
        checkSizeClicked();
    }
});


/* STORE LOC */
// Creation d'un bloc correspondant a un marqueur sur la liste laterale
function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng, horaire_plus) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement('li');
    li.id = id;

    li.className = 'elem-list-store';

    if (lat != "" && lng != "" && !$('body.product_page').length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }


    /***** Product stock infos *****/
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';
        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var info_horaire_today = !horaire_today ? Translator.translate("store_closed") : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';
    var ligneListe =
        '<div class="elem_list_contents">' +
        '<div class="nom_store">';
    
    if (distance) {
        ligneListe += '<h2 class="title">' + nom +  '<p class="distance">' + '('+ distance + 'km)' + '</p></h2>';
    } else {
        ligneListe += '<h2 class="title">' + nom + '</h2>';
    }
    ligneListe += '</div>' +
    '<div class="content_store">'
    
    if ($('body.product_page').length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
    } else {
        ligneListe += '<div class="detail_store">'
    }
    ligneListe += '<div class="col-1">' +
    '<span class="rue_store">' + adresse.toLowerCase() + '</span>' +
    '<span class="ville_store">' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ' - ' + '<span class="pays_store">' + pays + '</span>' + '</span>' +
    '<span class="tel_store">' + telephone + '</span></div>';


    //for (var i = 0; i < picture_filtre.length; i++) {
    //	ligneListe += '<div class="filtre_store"><img src="' + path_relative_root + picture_filtre[i] + '" /></div>';
    //}

    var is_page_product = $('body.product_page').length == 1;

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    }

    if (horaire_plus != '' && horaire_plus != 'undefined') {
        ligneListe += '<div class="horaire_plus">' + horaire_plus + '</div>';
    }

    ligneListe += '</div>';
    if( document.getElementById('produit_id') ) {

        /***** Product stock infos msg *****/
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }
    if (is_page_product) {

        if (beContacted == 1) {
            if ($('body.product_page.mobile').length) {
                ligneListe += '<div class="availableTxt store_more" onclick="handleChangeMobile('+ id +')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += '<div class="availableTxt store_more" onclick="handleChange(\''+ id +'\',\''+ nom_mag + '\')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            }
        } else {
            ligneListe+= '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="createDataLayer(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('store_more') + '</a>'
        + '</div>';
    }

    ligneListe+= '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;

    li.addEventListener('click', function () {
        google.maps.event.trigger(marqueur, 'click');
    });


    return li;
}

function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url, distance, horaire_today, currently_open) {

    var img = path_relative_root + "img/marker-7.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(img,
        // This marker is 35 pixels wide by 45 pixels tall.
        new google.maps.Size(35, 45),
        // The origin for this image is 0,0.
        new google.maps.Point(0,0));

        // The anchor for this image is the base of the flagpole at 0,32.
        //new google.maps.Point(10, 26));
        var shadow = new google.maps.MarkerImage(path_relative_root + 'img/indic_carte_ombre.png',
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0,0),
        new google.maps.Point(10,0));
        // Shapes define the clickable region of the icon.
        // The type defines an HTML <area> element 'poly' which
        // traces out a polygon as a series of X,Y points. The final
        // coordinate closes the poly by connecting to the first
        // coordinate.

    var marqueur = new google.maps.Marker({
        position: point,
        icon: image
    });
    
    marqueur.set("magasin_id", magasin_id);

    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? '' : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_close';
    var currently_open_info = currently_open ? Translator.translate('store_opened') : Translator.translate('store_closed');


    var cpt_recherche = ( typeof(cpt) != 'undefined' )? '('+cpt+') - ':'';

    if(distance) {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a><span class="dist">(' + distance + ' km)</span>';
    } else {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a></div>';
    }

    /*
    if( image_mag != '' ) {
        ligneListe += '<img src="' + image_mag + '" />';
    }
    */

    ligneListe += `<div class="adresse">
        <span class="adresse">${adresse}</span>
        <br>
        <span class="cp">${cp}</span> <span class="ville">${ville}</span> - <span class="pays">${pays}</span>
    </div>`;

    if (telephone != '')
        ligneListe += '<div class="coord">' + telephone + '</div>';

    /* if (email != '')
        ligneListe += '<div class="coord">Email : ' + email + '</div>'; */

    if (site != '') {

        if(!http.test(site)){
            ligneListe += '<a href="http://' + site + '" class="site" target="_blank">' + site + '</a>';
        }else{
            ligneListe += '<a href="' + site + '" class="site" target="_blank">' + site + '</a>';
        }
    }

    if (horaire != '') {
        if(class_horaire_today == 'store_open'){
            ligneListe += '<div class="horaire_title"><span class = "'+class_horaire_today+'">'+ currently_open_info + '</span>' + info_horaire_today +'</div><div class="horaire_info">' + horaire + '</div>';
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe += '<div class="horaire_title"><span class = "'+class_horaire_today+'">'+ currently_open_info + '</span></div><div class="horaire_info">' + horaire + '</div>';
        }

        
    }

    

    ligneListe += '<div class="btn_marker_container">';

    ligneListe += '<div class="btn_aller_magasin"><a href="https://maps.google.fr/maps?daddr=' + point.lat() + ',' + point.lng() + '">' + Translator.translate('go_to_store') + '</a></div>';

    ligneListe += '<div class="btn_voir_magasin"><a href="' + url + '">' + Translator.translate('store_more') + '</a></div></div>';

    ligneListe += '</div>';

    google.maps.event.addListener(marqueur, "click", function() {
        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });
    
    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id
    
    markersArray.push(marqueur);
    
    return marqueur;

}

function changeSymbolSchedule(schedulesArg) {
    var body = document.body;
    var replacementSymbol = body.classList.contains('fr') ? 'h' : ':';
    schedules = schedulesArg;
    schedules.forEach(scheduleElement => {
        if (replacementSymbol === ':') {

            scheduleElement.innerHTML = scheduleElement.innerHTML.replace(/(\d)h(\d)/g, `$1${replacementSymbol}$2`);
        }
    });
}


// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});
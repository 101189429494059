/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;
function restoreWishlistButtonState() {
    $(".addWishlistBtn").html(button_add_to_wishlist_initial_label);
    $(".addWishlistBtn")
        .removeClass("existToWishlistButton")
        .addClass("addToWishlistButton");
    $(".addWishlistBtn")
        .attr("onclick", button_add_to_wishlist_initial_click_event)
        .attr("href", "#");
}
/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist() {
    var btn = $(this);
    var idElt = btn.data('idelt');
    var productId = btn.data('productid');
    var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    var sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    $("#ligne_pointure_error_"+idElt).remove();

    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: 'true'
    };
    if (!btn.hasClass('existToWishlistButton')) {
        // Ajax add to wishlist

        $.ajax({
            url: path_relative_root + create_link("ajax_add_to_wishlist"),
            type : 'post',
            data: datas
        })
            .done(function(resp) {
                var $alertboxLogin = $("#abox");
                $alertboxLogin.addClass('box_wishlist');
                if (resp.error) {
                    if (resp.message === "login_required") { // error not connected
                        var html =
                            '<div class="box_wishlist">' +
                            resp.result.error_message +
                            '<div class="wrapper_btn">' +
                            '<a class="button primary" onclick="wishlistRedirectLogin(\'' + productId + '\');">' +
                            resp.result.btn_connexion +
                            "</a>" +
                            "</div>" +
                            "</div>";

                        if ($('#lightbox_achat_express').length > 0) {
                            $('#lightbox_achat_express').fadeOut("fast", function () {
                                $('#shad').css('display', 'none');
                            });
                        }
                        alert(html);
                        $("#abox .form_submit").css("display", "none");
                    } else if (resp.message == "size") { // error no size
                        var html =
                            '<div class="box_wishlist">' +
                            Translator.translate('need_choose') +
                            "</div>";
                            alert(html);
                    } else {
                        if (resp.result.error_message) {
                            alert(Translator.translate(resp.result.error_message));
                        } else {
                            alert(Translator.translate('error'));
                        }
                        return false;
                    }
                } else {
                    changeBtnAddWishlist(true, idElt ?? productId);
                    var response_basket = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
                    $("#wishlist_top").html(response_basket);
                    $("#wishlist_top").addClass('hover');
                    topCartAppear($(".my_wishlist"));
                }
            });
    } else {

        if (datas.size_id !== "" && btn.hasClass('existToWishlistButton')) {
            // Ajax remove wishlist
            $.ajax({
                url: path_relative_root + "ajax_remove_from_wishlist.php",
                type: 'post',
                data: datas
            })
            .done(function (resp) {
                if (!resp.error) {
                    btn.removeClass("existToWishlistButton");
                    changeBtnAddWishlist(false, idElt ?? productId);
                }
            });
        } else if (btn.hasClass('existToWishlistButton')) {
            if (datas.size_id === "") {
                alert(Translator.translate('error_suppr_wishlist_taille'))
            }
            if (datas.color_id === "") {
                alert(Translator.translate('error_suppr_wishlist_couleur'));
            }
        }
    }
}

function showBoxWishlist()
{
    $(".box_wishlist").css({
        'z-index': 100,
        display: "block",
        opacity: 1
    });
    $(".box_wishlist").find(".close").click(function () {
        $(".box_wishlist").css({
           'z-index': 0,
            display: "block",
            opacity: 0

        });
    });
}

/**
 *
 * @param productId
 * @returns {boolean}
 */
 function checkProductInWishlist(productId, productRef, enableBtnWishlist = false) {

    var isProductPage = $("#produit_id").val();
    var colorId = $("#couleurProd_" + productId).val();
    var sizeId = $("#tailleProd_" + productId).val();
    var noSelect = ($("#tailleProd_" + productId).val() !== null && $("#couleurProd_" + productId).val() !== null) ? true : false;
    //Vérifie que productId est un string ou non pour split
    var productId_clean = (typeof productId === 'string' && productId.includes('_')) ? productId.split('_')[0] : productId;

    if (isProductPage > 0) {
        colorId = $("#couleurProd").val();
        sizeId = $("#tailleProd").val();
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        // var onclickAddToWishlist = "addToWishlist.call(this);";
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_wishlist"),
            data:{'product_id': productId_clean, 'size_id': sizeId, 'color_id': colorId, 'noSelect' : noSelect},
            success: function (response) {
                var userWishlist = JSON.parse(response);

                if (typeof userWishlist !== undefined && userWishlist.length > 0) {
                    changeBtnAddWishlist(true, productId);
                } else {
                    changeBtnAddWishlist(false, productId);
                }
            }
        });
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 */
function changeBtnAddWishlist(in_wishlist, productId) {
    btn = $("#addToWishlistButton_"+productId);
    var productId_clean = '';
    productId_clean = (typeof productId === 'string' && productId.includes('_')) ? productId.split('_')[0] : productId;
    // Handle if product is in wishlist or not
    if (in_wishlist) {
        //Vérifie que productId est un string ou non pour split
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
        $('#wishlistButton_'+productId_clean).removeClass('addToWishlistButtonPreview');
        $('#wishlistButton_'+productId_clean).addClass('existToWishlistButtonPreview');
    } else {
        $('#wishlistButton_'+productId_clean).removeClass('existToWishlistButtonPreview');
        $('#wishlistButton_'+productId_clean).addClass('addToWishlistButtonPreview');
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

function wishlistRedirectLogin(div_id) {
    $.get(
        path_relative_root + create_link("ajax_add_to_wishlist") + '?login_required=1',
        function() {
            document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
        }
    );
}

function scanForChanges() {
    var totalPrice = 0;
    var totalPoints = 0;
    selectedProducts = [];
    wishlistProductData = [];
    selectedProductsObj = {};

    $(".checkbox_select_product").each(function() {
        var element = $(this);

        /**
         * Sélectionne le bloc .product_ctn
         */
        var productCnt = $(this).closest('.product_ctn');

        if (element.is(":checked")) {
            var price = parseFloat(element.attr("data-price"));

            if (price) {
                totalPrice += price;
            }

            wishlistProductData.push({
                productId: element.attr("data-produit-id"),
                colorId: element.attr("data-color-id"),
                sizeId: element.attr("data-size-id"),
            });

            selectedProductsObj.selectedProducts = JSON.stringify(wishlistProductData);
            selectedProducts.push(element.attr("data-id"));

            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }

            /**
             * Ajoutez la classe
             * 'checked'
             */
            productCnt.addClass('checked');
        } else {
            /**
             * Retirez la classe
             * 'checked'
             */
            if (productCnt.hasClass('checked')) {
                productCnt.removeClass('checked');
            }
        }
    });

    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + " :";
            priceSpan.html(
                '<span id="total_price">0<sup></sup><span class="decimal_price"></span><span class="devise">' + siteCurrency.symbol_right + '</span></span>'
            );
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == "") text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = "";
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = "" + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + "0";
                }
            }

            var priceSpanHtml = '<span id="total_price">' + pricefloor + '<sup></sup>';
            priceSpanHtml += (decimal > 0) ? '<span class="decimal_price">,' + decimal + '</span>' : '';
            priceSpanHtml += '<span class="devise">' + siteCurrency.symbol_right + '</span></span>';

            priceSpan.html(priceSpanHtml);
            txt =
                '<span class="wishlist_count">' +
                selectedProducts.length +
                "</span> " +
                text;
    }

    $(".txt", "#nrArticlesSelected").html(txt);
}
function addProductToCart(wpId, pId, sId, cId) {
    $.post(
        path_relative_root + create_link("ajax_wishlist_add_product_to_cart"),
        {
            product_id: pId,
            size_id: sId,
            color_id: cId,
        },
        function(resp) {
            if (resp == "ok") {
                document.location.href =
                    path_relative_root + create_link("order_basket");
            } else {
                if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                    alert(Translator.translate("err_no_points"));
                }
            }
        }
    );
}

/**
 * Ferme une popup par rapport à son nom et ferme aussi le shad
 * Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php
 */
function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = "shad";
    }
    var $popup = $("#" + popup_id),
        $shad = $("#" + shad_id);

    $shad.unbind("click.popup");
    $popup.slideUp("slow", function() {
        $shad.fadeOut("slow", function() {
            if ($("#wrapper_sendwishlist").hasClass("sended")) {
                $("#sendwishlistbox").remove();
            }
        });
    });
}

/**
 * Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
 * Utilisation : wishlist_index.php
 */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

/**
 * Envoie le formulaire de la lightbox wishlistavec gestion des erreurs
 * Utilisation : wishlist_index.php
 */
function sendWishListForm() {
    $("#sendWishListSubmit").css({display: "none"});
    $("#wrapper_loader_send").css({display: "block"});
    var content_id = $("#send_wishlist_ajax");
    var content;
    if (
        $("#sendWishListToMails").length &&
        $("#sendWishListToMessage").length &&
        $("#sendWishListYourName").length &&
        $("#sendWishListYourMail").length
    ) {
        var to_mails = $("#sendWishListToMails").val();
        var to_message = $("#sendWishListToMessage").val();
        var your_name = $("#sendWishListYourName").val();
        var your_mail = $("#sendWishListYourMail").val();
        var token_security = $("#token_security_wishlist").val();
        var captcha = $("#g-recaptcha-response").val();

        $.ajax({
          url:
            path_relative_root +
            create_link("ajax_send_wishlist_form") +
            "?to_mails=" +
            to_mails +
            "&to_message=" +
            to_message +
            "&your_name=" +
            your_name +
            "&your_mail=" +
            your_mail +
            "&token_security=" +
            token_security +
            "&g-recaptcha-response=" +
            captcha
        }).done(function(response) {
          $(".zone_text").css({ border: "" });

          if (response.split(",")[0] == "ok") {
            var s_adresse = response.substr(3);

            var sing =
              response.split(",").length > 2
                ? "sendfriend_ok_plur"
                : "sendfriend_ok_sing";

            content =
              "<div>" + Translator.translate(sing) +
              "</div>";
            content +=
              '<p style="margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;">' +
              s_adresse +
              "</p>";
          }
          if (response == "1" || response == "2") {
            content =
              '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_mail_friends_invalid') +
              "</p>";
            $("#sendWishListToMails").addClass("inputErr");
          } else {
            $("#sendWishListToMails").removeClass("inputErr");
          }
          if (response == "3") {
            content =
              '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_message') +
              "</p>";
            $("#sendWishListToMessage").addClass("inputErr");
          } else {
            $("#sendWishListToMessage").removeClass("inputErr");
          }
          if (response == "4") {
            content =
              '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_name') +
              "</p>";
            $("#sendWishListYourName").addClass("inputErr");
          } else {
            $("#sendWishListYourName").removeClass("inputErr");
          }
          if (response == "5" || response == "6") {
            $("#sendWishListYourMail").addClass("inputErr");

            if (response == "5") {
              content =
                '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_no_my_mail') +
                "</p>";
            } else {
              content =
                '<p class="txt_err_sendfriend">' + Translator.translate('sendfriend_error_my_mail_invalid') +
                "</p>";
            }
          } else {
            $("#sendWishListYourMail").removeClass("inputErr");
          }

          if (response == "token_error") {
            content =
              '<p class="txt_err_sendfriend">' + Translator.translate('error_token_security') + '</p>';
          }

          if (response.split(",")[0] == "7") {
            var s_adresse = response.substr(3);
            var sing =
              response.split(",").length > 2
                ? "sendfriend_no_ok_plur"
                : "sendfriend_no_ok_sing";
            content =
              "<div>" + Translator.translate(sing) + "</div>";
          }
          if (
            response.split(",")[0] == "7" ||
            response.split(",")[0] == "token_error" ||
            response.split(",")[0] == "ok"
          ) {
            content_id = $("#sendfriend_alert_mail");

            $("#sendfriend_alert_mail").css({
              display: "block",
              opacity: 0
            });
            $("#wrapper_sendwishlist")
              .addClass("sended")
              .css({ display: "block", opacity: 1 })
              .animate({ opacity: 0 }, function() {
                $("#wrapper_sendwishlist").css({ display: "none" });
                $("#sendfriend_alert_mail").animate(
                  { opacity: 1 },
                  function() {
                    $("#sendfriend_alert_mail").css({
                      display: "block"
                    });
                  }
                );
              });
          } else {
            $("#sendfriend_form_buttons").removeClass("loading");
          }

          content_id.html(content).css({ display: "block" });
        });
    }

    return false;
}

//Function to load previous selected Size product in basket
function loadSelectedSize(cnt_sizes) {

    var sizes = document.getElementsByName('itm_size');

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link("ajax_size_selected"),
        success: function (response) {
            if (response && response.length > 0) {
                response = $.parseJSON(response);
                var selected_size = (response.selected_size);
                var current_size;
                var current_element;
                var sizeProd = document.getElementById("tailleProd");
                var html = '';

                for (var i = 0; i < sizes.length; i++) {
                    current_element = sizes.item(i).value;
                    if ((selected_size === current_element && current_element !== null) && (!sizes.item(i).classList.contains("disabled"))) {
                        sizes.item(i).checked = true;
                        sizeProd.value = current_element;
                        enableAddCart();
                    }
                }
            }
        }
    });
}

// Add product to basket from wishlist (connected)
function moveProductFromWishlistToCart(elemBtn = false, selectedProducts = {}) {

    let datas = {};

    if (elemBtn) {
        var prodId = $(elemBtn).attr("data-produit-id");
        var colorId = $(elemBtn).attr("data-color-id");
        var sizeId = $(elemBtn).attr("data-size-id");

        datas = {
            data: $(elemBtn).attr("data-id"),
        };

        if (prodId !== undefined) {
            datas.productId = prodId;
        }

        if (colorId !== undefined) {
            datas.colorId = colorId;
        }

        if (sizeId !== undefined) {
            datas.sizeId = sizeId;
        }
    } else {
        datas = selectedProducts;
    }

    $.post(path_relative_root + create_link("ajax_add_panier_wishlist"), datas, function(resp) {
        var data_layer_products = window.data_layer_products;
        var dtl_products = [];
        if (resp == "ok") {
            if (window.dataLayer && data_layer_products) {
                if (Object.keys(data_layer_products).length > 0) {
                    for (const property in data_layer_products) {
                        if (array_wishlist_product_ids.includes(parseInt(property))) {
                            dtl_products.push(data_layer_products[property])
                        }
                    }
                }

                if (dtl_products.length > 0) {
                    window.dataLayer.push({
                        "event": "addToCart",
                        "ecommerce": {
                            'currencyCode' : siteCurrency.code,
                            "add": {
                                "products": dtl_products
                            }
                        }
                    })
                }
            }
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

/**
 * Allows you to add a product to the wishlist via the ray (or search)
 * app/_components/product/achat_express_search.php
 * app/_components/product/achat_express.php
 * If user is connect, display wishlist lightbox (achat express wishlist = true)
 * if not, call the product page addToWishlist function which already display a connect lightbox
 */
function addToWishlistRay() {
    addToWishlist.call(this);
}

function remove_product_from_wishlist(datas, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), datas, callback);
}

function ajax_RemoveWishlistProd(elemBtn) {

    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    let datas = {
        id: $(elemBtn).attr("data-id"),
        remove: true
    };

    if (prodId !== undefined) {
        datas.product_id = prodId;
    }

    if (colorId !== undefined) {
        datas.color_id = colorId;
    }

    if (sizeId !== undefined) {
        datas.size_id = sizeId;
    }

    remove_product_from_wishlist(datas, function(data) {
        if (data.result) {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}
/* ---------------------------------------------------------
FP E-RESA
--------------------------------------------------------- */
// Fix bug z-index select country phone
$(".selected-flag, .country-list li").click(function(e) {
    e.preventDefault();
    setTimeout(function(){
        if ($('.country-list').css('display') === 'block'){
            $('.bookinstore_form_client .optin').css('z-index', '-1');
        }else{
            $('.bookinstore_form_client .optin').css('z-index', 1);
        }
    }, 50);
});

// VARIABLE COLOR HEXA & avaibility_text E-RESA
var colorHexaBookInStore = true;

$(function(){
    if ( $('#e-resaBox').length ) {
        $('#e-resaBox').overlayScrollbars({});
    }
    if ( $('#e-resaBox') && $('body').hasClass('en') ) {
        $(document).ajaxComplete( function () {
            setTimeout(function(){
                var arrayTxt = $('.en .schedule_store').html();
                $('.en .schedule_store').empty().html(arrayTxt.replace(' et ',' and '));
            }, 100);
        });
    }
});